
/*cc LIGHT BOX*/
.cc-lightbox .close-div {
    color: #fff;
    font-size: 4.5em;
    cursor: pointer;
    line-height: 1;
    position: absolute;
    width: 100%;
    z-index: 2000;
  }
  .cc-lightbox {
    display: none;
    position: fixed;
    z-index: 2000;
    padding-top: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: transparent;
  }
  .cc-lightbox:before {
    content: " "; 
    position: fixed; 
    top: 0; 
    left: 0; 
    width: 100vw; 
    height: 100vh; 
    background-color: #000; 
    opacity: 0.9; 
    z-index: 0;
  }
  .cc-lightbox-content {
    position: relative;
    margin: auto;
    padding: 0;
    width: calc(100% - 80px);
    max-width: 1500px;
    height: 100vh;
  }
  .cc-lightbox-slide {
    display: none;
    max-height: 90vh;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
  }
  .cc-lightbox-slide .numbertext {
    color: #fff;
    font-size: .8em;
    padding: 5px;
  }
  .cc-lightbox-slide img {
    max-height: 85vh;
    margin: 0 auto;
    width: auto;
    max-width: 100%;
  }
  /* Next & previous buttons */
  .cc-lightbox-content .prev,
  .cc-lightbox-content .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 5px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 3em;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  /* Position the "next button" to the right */
  .cc-lightbox-content .next {
    right: -40px;
    border-radius: 3px 0 0 3px;
  }
  /* Position the "prev button" to the left */
  .cc-lightbox-content .prev {
    left: -40px;
  }
  /* On hover, add a black background color with a little bit see-through */
  .cc-lightbox-content .prev:hover,
  .cc-lightbox-content .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  