/* SVG */
.sprite-icon {
    background-image: url(//script.newwwebcms.dk/gfx/sprite-icons-singleline-16x16.svg);
    background-repeat: no-repeat;
    height: 16px;
    width: 16px;
    display: inline-block;
    filter : invert(38%) sepia(4%) saturate(28%) hue-rotate(337deg) brightness(83%) contrast(92%);
    position: relative;
    vertical-align: text-bottom;
  }
  .sprite-icon-white {
    filter: invert(100%) sepia(92%) saturate(3%) hue-rotate(200deg) brightness(108%) contrast(100%);
  }
  .sprite-icon-black {
    filter: invert(0%) sepia(5%) saturate(7481%) hue-rotate(228deg) brightness(98%) contrast(106%);
  }
  
  .icon-mail {
    background-position: 0 0;
  }
  .icon-cookie {
    background-position: 0 100%;
  }
  .icon-phone {
    background-position: calc(100% * (18 / 432)) 0;
  }
  .icon-energymarker-a-g {
    background-position: calc(100% * (18 / 432)) 100%;
    width: 8px !important;
    margin-top: 2px;
  }
  .icon-cellphone {
    background-position: calc(100% * (36 / 432)) 0;
  }
  .icon-pin {
    background-position: calc(100% * (54 / 432)) 0;
  }
  .icon-globe {
    background-position: calc(100% * (54 / 432)) 100%;
  }
  .icon-menu {
    background-position: calc(100% * (72 / 432)) 0;
  }
  .icon-link-out {
    background-position: calc(100% * (72 / 432)) 100%;
  }
  .icon-gallery {
    background-position: calc(100% * (90 / 432)) 0;
  }
  .icon-folder {
    background-position: calc(100% * (90 / 432)) 100%;
  }
  .icon-search {
    background-position: calc(100% * (108 / 432)) 0;
  }
  .icon-filter {
    background-position: calc(100% * (108 / 432)) 100%;
  }
  .icon-plus, 
  .icon-close {
    background-position: calc(100% * (126 / 432)) 0;
  }
  .icon-close {
    transform: rotate(45deg);
  }
  .icon-minus {
    background-position: calc(100% * (126 / 432)) 100%;
  }
  .icon-arrow-up, .icon-arrow-down, .icon-arrow-previous, .icon-arrow-next {
    background-position: calc(100% * (144 / 432)) 0;
  }
  .icon-arrow-down {
    transform: rotate(180deg);
  }
  .icon-arrow-previous {
    transform: rotate(270deg);
  }
  .icon-arrow-next{
    transform: rotate(90deg);
  }
  .icon-checked {
    background-position: calc(100% * (144 / 432)) 100%;
  }
  .icon-basket {
    background-position: calc(100% * (162 / 432)) 0;
  }
  @media screen and (max-width: 750px) {
    .icon-basket {
        height: 24px;
        background-position-y: 1px;
        width: 24px;
        background-size: 672px 51px;
    }
  }
  .icon-print {
    background-position: calc(100% * (162 / 432)) 100%;
  }
  .icon-package {
    background-position: calc(100% * (180 / 432)) 0;
  }
  .icon-document {
    background-position: calc(100% * (180 / 432)) 100%;
  }
  .icon-truck {
    background-position: calc(100% * (198 / 432)) 0;
  }
  .icon-server {
    background-position: calc(100% * (198 / 432)) 100%;
  }
  .icon-support {
    background-position: calc(100% * (216 / 432)) 0;
  }
  .icon-image {
    background-position: calc(100% * (216 / 432)) 100%;
  }
  .icon-settings {
    background-position: calc(100% * (234 / 432)) 0;
  }
  .icon-relations {
    background-position: calc(100% * (234 / 432)) 100%;
  }
  .icon-user {
    background-position: calc(100% * (252 / 432)) 0;
  }
  .icon-edit {
    background-position: calc(100% * (252 / 432)) 100%;
  }
  .icon-user-lock {
    background-position: calc(100% * (270 / 432)) 0;
  }
  .icon-bin {
    background-position: calc(100% * (270 / 432)) 100%;
  }
  .icon-lock {
    background-position: calc(100% * (288 / 432)) 0;
  }
  .icon-stop {
    background-position: calc(100% * (288 / 432)) 100%;
  }
  .icon-string {
    background-position: calc(100% * (306 / 432)) 0;
  }
  .icon-number {
    background-position: calc(100% * (306 / 432)) 100%;
  }
  .icon-google {
    background-position: calc(100% * (324 / 432)) 0;
  }
  .icon-newwweb {
    background-position: calc(100% * (324 / 432)) 100%;
  }
  .icon-facebook {
    background-position: calc(100% * (342 / 432)) 0;
  }
  .icon-linkedin {
    background-position: calc(100% * (360 / 432)) 0;
  }
  .icon-instagram {
    background-position: calc(100% * (378 / 432)) 0;
  }
  .icon-youtube {
    background-position: calc(100% * (396 / 432)) 0;
  }
  .icon-pinterest {
    background-position: calc(100% * (414 / 432)) 0;
  }
  .icon-contentcloud {
    background-position: calc(100% * (432 / 432)) 100%;
  }
  
  .ncms-social-icon .sprite-icon {
    background-position-y: 100%;
  }
  .sprite-icon-x050 {
    height: 8px;
    width: 8px;
    background-size: 224px 17px;
  }
  .sprite-icon-x075 {
    height: 12px;
    width: 12px;
    background-size: 336px 25.5px;
  }
  .sprite-icon-x150 {
    height: 24px;
    width: 24px;
    background-size: 672px 51px;
  }
  .sprite-icon-x200 {
    height: 32px;
    width: 32px;
    background-size: 896px 68px;
  }
  .sprite-icon-x300 {
    height: 48px;
    width: 48px;
    background-size: 1344px 102px;
  }
  .sprite-icon-x400 {
    height: 64px;
    width: 64px;
    background-size: 1792px 136px;
  }
  