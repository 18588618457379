.react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative
}

.react-multi-carousel-track {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: transform,transition
}

.react-multiple-carousel__arrow {
    position: absolute;
    outline: 0;
    transition: all .5s;
    border-radius: 35px;
    z-index: 1000;
    border: 0;
    background: rgba(0,0,0,.5);
    min-width: 43px;
    min-height: 43px;
    opacity: 1;
    cursor: pointer
}

.react-multiple-carousel__arrow:hover {
    background: rgba(0,0,0,.8)
}

.react-multiple-carousel__arrow:before {
    content: "";
    height: 16px;
    width: 16px;
    /*background-size: 672px 51px; - 24px*/
    background-image: url(//script.newwwebcms.dk/gfx/sprite-icons-singleline-16x16.svg);
    background-position: calc(100% * (144 / 432)) 0;
    z-index: 2;
    position: absolute;
    display: inline-block;
    -webkit-filter: invert(100%) sepia(92%) saturate(3%) hue-rotate(200deg) brightness(108%) contrast(100%);
    filter: invert(100%) sepia(92%) saturate(3%) hue-rotate(200deg) brightness(108%) contrast(100%);
    top: 50%;
}

.react-multiple-carousel__arrow:disabled {
    cursor: default;
    background: rgba(0,0,0,.5)
}

.react-multiple-carousel__arrow--left {
    left: calc(4% + 1px)
}

.react-multiple-carousel__arrow--left:before {    
    left: 47%;
    -webkit-transform: translate(-50%, -50%) rotate(270deg);
    transform: translate(-50%, -50%) rotate(270deg);
}

.react-multiple-carousel__arrow--right {
    right: calc(4% + 1px)
}
.react-multiple-carousel__arrow--right:before {
    left: 53%;
    -webkit-transform: translate(-50%, -50%) rotate(90deg);
    transform: translate(-50%, -50%) rotate(90deg);
}

.react-multi-carousel-dot-list {
    position: absolute;
    bottom: 0;
    display: flex;
    left: 0;
    right: 0;
    justify-content: center;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center
}

.react-multi-carousel-dot button {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    opacity: 1;
    box-shadow: none;
    transition: background .5s;
    border: 2px solid grey;
    padding: 0;
    margin: 0 6px 0 0;
    outline: 0;
    cursor: pointer
}

.react-multi-carousel-dot--active button,.react-multi-carousel-dot button:hover:active {
    background: #080808
}

.react-multi-carousel-item {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

@media (-ms-high-contrast:active),(-ms-high-contrast:none) {
    .react-multi-carousel-item {
        flex-shrink: 0!important
    }

    .react-multi-carousel-track {
        overflow: visible!important
    }
}

/*# sourceMappingURL=2.e1b25a95.chunk.css.map */
